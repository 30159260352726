<div fxLayout="column" fxFlexFill style="height: 100%;">
  <mat-toolbar class="logoHeader" *ngIf="isSmallDevice">
    <div class="container-fluid">
      <div class="row matAllAlign">
        <div class="col-sm-12 pl-0 pr-0">
          <div class="float-left mt-1 navbar-fixes">
            <a [routerLink]="['/student/dashboard']">
              <img src="../../../../assets/yuvachetna/yuvachetna_logo.png" style="width: 15%; margin-top: -17px;"
                [routerLink]="['/student/dashboard']" alt="proteen-logo"
                class="navbarLogo cursor-pointer border-none pt-2" />
            </a>
            <!-- Adding the second logo -->
            <img src="../../../../assets/yuvachetna/powered by ProTeen_Color.png"
              style="width: 35%; margin-top: -17px; margin-left: 3%;" alt="proteen-logo"
              class="navbarLogo cursor-pointer border-none pb-1" />
          </div>
          <div class="float-right pb-1 d-flex">
            <div class="mat-align">
              <span class="d-flex flex-column">
                <span class="w-100 text-center mb-1" [style.marginTop]="activePlan == 'Premium' ? '12px' : 'auto'"
                  style="font-size:13px;letter-spacing: 0.3px;color: #36C1CF;" translate="no">
                  <div id="planDisplay" [innerHTML]="planText"></div>
                </span>
                <button *ngIf="activePlan === 'Basic'" mat-flat-button
                  class="action-btn action-btn-text mt-2 float-right sidebar-btn letter-spacing-light"
                  style="box-shadow: 0 0 10px rgb(0 0 0 / 20%); margin-top: -10px !important;" (click)="upgradeClick()"
                  translate="no">
                  <b>Yuva
                    <sup style="font-size: 18px;font-weight: bold; margin-top: 2px;">+</sup></b>
                  <!-- Upgrade -->
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar>

  <mat-drawer-container fxFlex autosize class="background mobileDrawer" *ngIf="isSmallDevice" autosize>
    <div class="closeSidebar" *ngIf="isSmallDevice">
      <button mat-button (click)="[(opened = !opened), update()]" [ngStyle]="{
          display:
            _psychometricService.showOverlayPersonality ||
            _psychometricService.showOverlayInterest
              ? 'none'
              : 'flex'
        }">
        <mat-icon class="{{ hamCenter ? 'menu-icon center-menu-icon' : 'menu-icon' }} {{
            hamPullUp ? 'ham-pull-up' : ''
          }}">menu</mat-icon>
      </button>
    </div>
    <mat-drawer #drawer [(opened)]="opened" class="mobileMatDrawer">
      <div class="row mb-5">
        <div class="col-sm-12 backRow">
          <div class="user-container d-flex">
            <div class="row w-100">
              <div class="col-sm-12" (click)="opened = !opened">
                <div class="sidebar-switch">
                  <mat-icon svgIcon="back-arrow" class="back-icon"></mat-icon>
                </div>
                <div class="sidebar-profile">
                  <img class="profile-avatar" src="{{
                      _studentService.s3Image
                        ? _studentService.s3Image
                        : '../../../../../../assets/images/profileTab/user-profile.svg'
                    }}" alt="" />
                  <div class="lg-text custom-align letter-spacing-light pt-3" translate="no">
                    {{ firstName }}
                  </div>
                  <!-- <div class="d-inline-flex">
                    <span class="sidebar-totalpoints">{{
                      this._studentService.totalPoints
                    }}</span>
                    <span class="sidebar-points">Pointssss</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column" class="sidebar">
        <ng-container *ngFor="
            let navItem of _studentService.navbarData;
            let navIndex = index
          ">
          <!--non-hierarchical menu-->
          <ng-container *ngIf="!navItem.children">
            <button mat-button [routerLink]="navItem.routerLink" routerLinkActive="selected no-border txt-bold"
              fxLayoutAlign="start" [routerLinkActiveOptions]="{ exact: true }" class="py-2 pr-5"
              (click)="[(opened = !opened), menuSelect()]" autofocus="false">
              <div class="d-inline-block">
                <mat-icon svgIcon="{{ navItem.icon }}" class="sidebar-icon"></mat-icon><span class="nav-text"
                  translate="no">{{
                  navItem.title
                  }}</span>
              </div>
            </button>
          </ng-container>

          <!--hierarchical menu-->
          <ng-container *ngIf="navItem.children">
            <mat-accordion>
              <mat-expansion-panel (opened)="navItem.opened = true" (click)="collapseAllExcept(navIndex)"
                (closed)="navItem.opened = false" [expanded]="navItem.opened" class="customMatExpansion">
                <!--parent menu-->
                <mat-expansion-panel-header>
                  <!--mat-panel-title>
                  <button mat-button routerLinkActive="selected no-border txt-bold"
                    fxLayoutAlign="start" class="py-2 pr-5" [ngClass]="{'firstExpansion1' : navItem.active}">
                    <div class="d-inline-block">
                      <mat-icon svgIcon="{{navItem.icon}}" class="sidebar-icon"></mat-icon>
                      <span class="nav-text">{{navItem.title}}</span></div>
                  </button>
                </mat-panel-title-->
                  <mat-panel-title>
                    <button mat-button fxLayoutAlign="start" class="py-1 pr-5 w-100" routerLinkActive=""
                      [routerLinkActiveOptions]="{ exact: true }">
                      <div class="d-inline-block">
                        <mat-icon svgIcon="{{ navItem.icon }}" class="sidebar-icon"></mat-icon>
                        <span class="nav-text" translate="no">{{
                          navItem.title
                          }}</span>
                      </div>
                    </button>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!--children menu-->
                <ng-container *ngFor="
                    let navChild of navItem.children;
                    let childrenIndex = index
                  ">
                  <button *ngIf="
                      isTeen ? navChild.title : navChild.title != 'All Streams'
                    " mat-button routerLinkActive="selected no-border txt-bold" fxLayoutAlign="start"
                    class="py-2 pr-5 d-inline-block" [routerLink]="[navChild.routerLink]" (click)="
                      [
                        (opened = !opened),
                        submenuSelect(
                          navIndex,
                          childrenIndex,
                          navChild.routerLink
                        )
                      ]
                    ">
                    <div class="d-inline-block">
                      <mat-icon svgIcon="{{ navChild.icon }}"
                        class="sidebar-icon submenu-icon justify-self-start m-0"></mat-icon>
                      <span class="nav-text" translate="no">{{
                        navChild.title
                        }}</span>
                    </div>
                  </button>
                </ng-container>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </ng-container>

        <button mat-button (click)="openDialog()" fxLayoutAlign="start" class="py-2 hide-log pr-5"
          style="margin-left: 3px;">
          <div class="d-inline-block">
            <mat-icon svgIcon="feedback" class="sidebar-icon logout-icon"></mat-icon><span class="nav-text"
              translate="no">Feedback</span>
          </div>
        </button>

        <button mat-button (click)="logout()" fxLayoutAlign="start" class="py-2 hide-log pr-5">
          <div class="d-inline-block">
            <mat-icon svgIcon="logout" class="sidebar-icon logout-icon"></mat-icon><span class="nav-text"
              translate="no">Logout</span>
          </div>
        </button>
      </div>
    </mat-drawer>

    <!--PAGES-->
    <div style="display: inline;">
      <router-outlet (activate)="checkChild($event)"></router-outlet>
    </div>
  </mat-drawer-container>

  <mat-toolbar class="navbar-color mat-elevation-z8 p-0 drawerHeader" fxLayout="row" fxLayoutAlign="start stretch"
    style="z-index: 2" *ngIf="isLargeDevice">
    <div class="container-fluid">
      <div class="row">
        <!-- <div class="col-md-2 pl-0 pr-md-2 text-center webkitScrollDisable">
          <img [routerLink]="['/student/dashboard']" src="../../../../assets/yuvachetna/yuvachetna_logo.png" href="#"
            alt="proteen-logo" class="logo cursor-pointer border-none"
            style="width: 6.3rem; margin-top: -10px; margin-left: -30px;">
        </div>
        <div class="col-md-5 pr-0 pl-md-2">
          <img [routerLink]="['/student/dashboard']" src="../../../../assets/yuvachetna/powered by ProTeen_Color.png"
            href="#" alt="proteen-logo" class="logo cursor-pointer border-none" style="width: 13.3rem;">
        </div> -->
        <div class="col-md-7 pl-0 pr-0">
          <img [routerLink]="['/student/dashboard']" src="../../../../assets/yuvachetna/yuvachetna_logo.png" href="#"
            alt="proteen-logo" class=" cursor-pointer border-none"
            style="width: 4rem; margin-top: 1px; margin-left: 4%;" />
          <img [routerLink]="['/student/dashboard']" src="../../../../assets/yuvachetna/powered by ProTeen_Color.png"
            href="#" alt="proteen-logo" class="cursor-pointer border-none" style="width: 7.3rem; margin-left: 1%;" />
        </div>

        <div class="col-md-5 justify-content-end d-flex pl-0">
          <div class="row" style="width: 100%;">
            <div class="col-md-1"></div>
            <!-- <div class="col-md-12 justify-content-end d-flex"> -->
            <div class="col-md-6 sectionDivider px-3">
              <div class="row justify-content-around d-flex">
                <!-- <div class="col-md-12 justify-content-end d-flex"> -->
                <!-- <div class="col-md-6 pt-4"> -->
                <div class="smallText text-left lineHeight letter-spacing-light pt-3 font-bold textColor"
                  style="font-size: 16px;" translate="no">
                  <div id="planDisplay" [innerHTML]="planText"></div>
                  <!-- {{ activePlan }} Plan -->
                </div>
                <!-- </div>
                      <div class="col-md-6 pt-2"> -->
                <button *ngIf="activePlan === 'Basic'" mat-flat-button
                  class="action-btn action-btn-text mt-2 float-right sidebar-btn letter-spacing-light"
                  style="box-shadow: 0 0 10px rgb(0 0 0 / 20%);" (click)="upgradeClick()" translate="no">
                  <b>Yuva
                    <sup style="font-size: 18px;font-weight: bold; margin-top: 2px;">+</sup></b>
                  <!-- Upgrade -->
                </button>
                <!-- </div> -->
                <!-- </div> -->
              </div>
            </div>

            <div class="col-md-5">
              <div class="row">
                <div class="col-7  justify-content-end d-flex pr-0 pl-0">
                  <div class="d-block smallText mt-3 w-100">
                    <div class="font-bold letter-spacing text-left ellipsis" translate="no">
                      {{ firstName }}
                    </div>
                    <!-- <div class="text-left ml-1">
                      <div class="font-bold letter-spacing t-orange font-14 totalPoints">
                        {{this._studentService.totalPoints}}
                      </div><span style="font-size: 11px;" translate="no">Points</span>
                      
                      <img class="information-icon" matTooltipPosition="below" matTooltip="{{getMoreInformation()}}"
                        matTooltipClass="toltipclass" matTooltipPosition="left"
                        src="../../../../assets/svg/info_new.svg">
                    </div> -->
                  </div>
                </div>
                <div class="col pt-2 pl-1 justify-content-center d-flex">
                  <div class="row">
                    <!-- <div class="col-md-12 justify-content-end d-flex"> -->
                    <div class="portrait" (click)="showDropdown($event)">
                      <img class="profile-image" src="{{
                          _studentService.s3Image
                            ? _studentService.s3Image
                            : '../../../../assets/images/other/profile-user.svg'
                        }}" style="width:36px;height:36px;" />
                    </div>
                    <div class="dropdown" style="float:left;" (click)="showDropdown($event)">
                      <button class="dropbtn">
                        <span class="material-icons" translate="no">
                          keyboard_arrow_down
                        </span>
                      </button>
                      <div class="dropdown-content arrow-up" *ngIf="showHideDropdown">
                        <!-- <a href="#"><mat-icon svgIcon="security" class="sidebar-icon"></mat-icon>Security</a>
                          <a href="#"><mat-icon svgIcon="help" class="sidebar-icon"></mat-icon>Help</a> -->
                        <!-- <a (click)="upgradeClick()"><mat-icon svgIcon="pricing1" class="sidebar-icon" style="height: 20px;width: 20px;"></mat-icon>Pricing</a> -->
                        <a (click)="logout()"><mat-icon svgIcon="logout" class="sidebar-icon"></mat-icon>Logout</a>
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <span fxFlex></span>
  </mat-toolbar>

  <mat-drawer-container fxFlex autosize class="background" *ngIf="isLargeDevice"
    [ngClass]="showCollapsedSidebar ? 'sidebar-open' : 'sidebar-close'" autosize>
    <mat-drawer class="customized-drawer" #drawer2 [ngClass]="showCollapsedSidebar ? 'iconsDrawer' : 'fullWidthDrawer'"
      mode="side" opened (keydown.escape)="close('escape')">
      <div fxLayout="column" class="sidebar">
        <!--back and ham icons-->
        <div class="collapsableSidebar" (click)="collapsableSidebar()" [ngClass]="{
            backPadding: !showCollapsedSidebar,
            'pr-5': showCollapsedSidebar,
            hamPadding: showCollapsedSidebar
          }">
          <mat-icon (click)="opened = !opened" svgIcon="back-icon" class="menu-icon back" *ngIf="!showCollapsedSidebar"
            style="height: 18px;"></mat-icon>
          <mat-icon (click)="opened = !opened" class="menu-icon" *ngIf="showCollapsedSidebar"
            translate="no">menu</mat-icon>
          <!-- <mat-icon *ngIf="!showCollapsedSidebar" class="pull-right">push_pin</mat-icon> -->
        </div>
        <ng-container *ngFor="
            let navItem of _studentService.navbarData;
            let navIndex = index
          ">
          <ng-container *ngIf="!navItem.children">
            <button mat-button [routerLink]="[navItem.routerLink]" routerLinkActive="activeHome"
              [routerLinkActiveOptions]="{ exact: true }" fxLayoutAlign="start" class="py-2 pr-5 "
              (click)="menuSelect()">
              <div class="d-inline-block">
                <mat-icon svgIcon="{{ navItem.icon }}" class="sidebar-icon"
                  [matTooltip]="showCollapsedSidebar ? navItem.title : ''" matTooltipPosition="right"
                  matTooltipClass="customizeTooltip"></mat-icon>
                <span class="nav-text" *ngIf="!showCollapsedSidebar" translate="no">{{ navItem.title }}</span>
              </div>
            </button>
          </ng-container>
          <!--menu with children-->
          <ng-container *ngIf="navItem.children">
            <mat-accordion *ngIf="!showCollapsedSidebar">
              <!-- {{navItem.opened}} -->
              <div [ngClass]="{ 'apply-padding': navItem.opened }">
                <mat-expansion-panel (opened)="navItem.opened = true" (closed)="navItem.opened = false"
                  [expanded]="navItem.opened" (click)="collapseAllExcept(navIndex)" class="customMatExpansion">
                  <!--parent menu-->
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <button mat-button fxLayoutAlign="start" class="py-1 pr-5 w-100" routerLinkActive=""
                        [routerLinkActiveOptions]="{ exact: true }">
                        <div class="d-inline-block">
                          <mat-icon svgIcon="{{ navItem.icon }}" class="sidebar-icon"></mat-icon><span class="nav-text"
                            translate="no">{{
                            navItem.title
                            }}</span>
                        </div>
                      </button>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <!--children-->
                  <ng-container *ngFor="
                      let navChild of navItem.children;
                      let childrenIndex = index
                    ">
                    <button *ngIf="
                        isTeen
                          ? navChild.title
                          : navChild.title != 'All Streams'
                      " mat-button routerLinkActive="selected no-border txt-bold"
                      [routerLinkActiveOptions]="{ exact: true }" class="py-1 pr-5 w-100 d-flex"
                      [routerLink]="[navChild.routerLink]" (click)="
                        submenuSelect(
                          navIndex,
                          childrenIndex,
                          navChild.routerLink
                        )
                      ">
                      <div class="d-flex align-items-center w-100">
                        <mat-icon svgIcon="{{ navChild.icon }}"
                          class="sidebar-icon submenu-icon justify-self-start m-0"></mat-icon>

                        <span class="nav-text" translate="no">{{
                          navChild.title
                          }}</span>
                      </div>
                    </button>
                  </ng-container>
                </mat-expansion-panel>
              </div>
            </mat-accordion>

            <div style="position: relative;" *ngIf="showCollapsedSidebar">
              <button mat-button [routerLink]="[navItem.routerLink]" routerLinkActive="activeHome"
                [routerLinkActiveOptions]="{ exact: true }" fxLayoutAlign="start" [ngClass]="[
                  showCollapsedSidebar ? 'assessment-btn' : '',
                  showFirstTabHighlight ? 'firstTabHighlight' : '',
                  navItem.active ? 'firstExpansion1' : ''
                ]" class="py-2 pr-5" (click)="menuSelect()" style="position: relative;">
                <div class="d-inline-block">
                  <mat-icon svgIcon="{{ navItem.icon }}" class="sidebar-icon"></mat-icon>
                </div>
              </button>

              <div class="onhover-menu modal-content" [style.height.px]="navItem.title == 'Report' ? '60' : '120'"
                *ngIf="showCollapsedSidebar">
                <div class="menu-overlay position-absolute d-block" style="width: 100%;">
                  <div class="s-menu" *ngFor="
                      let navChild of navItem.children;
                      let childrenIndex = index
                    ">
                    <a [routerLink]="[navChild.routerLink]" routerLinkActive="selected no-border txt-bold"
                      [routerLinkActiveOptions]="{ exact: true }"><span (click)="
                          submenuSelect(
                            navIndex,
                            childrenIndex,
                            navChild.routerLink
                          )
                        " class="nav-text pl-0 mr-0" translate="no">{{ navChild.title }}</span></a>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <button mat-button (click)="openDialog()" fxLayoutAlign="start" class="py-2 pr-5 ">
          <div class="d-inline-block">
            <mat-icon svgIcon="feedback" class="sidebar-icon" [matTooltip]="showCollapsedSidebar ? 'Feedback' : ''"
              matTooltipPosition="right" matTooltipClass="customizeTooltip"></mat-icon>
            <span class="nav-text" *ngIf="!showCollapsedSidebar" translate="no">Feedback</span>
          </div>
        </button>
        <!--
          <button mat-button class="py-2 pr-5 " (click)="openDialog()" fxLayoutAlign="start">
          <div class="d-inline-block"><mat-icon svgIcon="feedback" class="sidebar-icon"></mat-icon><span class="nav-text" translate="no">Feedback</span></div>
        </button> -->
      </div>
    </mat-drawer>

    <!--PAGES-->
    <div style="display: inline;">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-container>
</div>