<div *ngIf="isLargeDevice" style="height: 100vh; background-color: #36c1cf;">
  <div class="container-fluid px-0" style="height: 100%; background-color: #36c1cf;">
    <div class="col-md-12" style="position: absolute;">
      <!-- added partner logo on student login page 01022023 -->
      <img src="../../../../assets/yuvachetna/powered by ProTeen_White.png" style="width: 12rem; margin-top: 6px;" />
    </div>

    <div class="login-container signin-container">
      <div class="row vertical-center">
        <div class="col-md-7 col-sm-12">
          <div class="col-md-12">
            <div class="col-md-12" style="display: flex; justify-content: center;background-color: #36C1CF;">
              <p style="    font-weight: bold;
                                color: white;
                                font-size: 35px;
                                margin-top: 3%;">
                Welcome to
              </p>
            </div>
            <div class="col-md-12" style="display: flex; justify-content: center;background-color: #36C1CF;">
              <img src="../../../../assets/yuvachetna/yuvachetna_logo.png" height="270px" />
            </div>
            <div class="col-md-12" style="display: flex; justify-content: center;background-color: #36C1CF;">
              <h2 style="font-weight: bold;color: white;">
                Fostering mindfulness among Indian youth
              </h2>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12 sign-in-box">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isSmallDevice">
  <div class="container-fluid px-0" style="height: 100vh; background-color: #36c1cf;">
    <div class="col-md-12" style="position: relative;">
      <!-- Top left: Powered by logo -->
      <img src="../../../../assets/yuvachetna/powered by ProTeen_White.png"
        style="width: 8rem; position: absolute; top: 0; left: 0; margin: 6px;" />

      <!-- Centered: Yuvachetna logo -->
      <div style="display: flex; justify-content: center;">
        <img src="../../../../assets/yuvachetna/yuvachetna_logo.png" style="height: 9rem; margin-top: -6px;" />
      </div>
    </div>

    <div class="col-md-12" style="display: flex; justify-content: center;background-color: #36C1CF;">
      <h3 style="font-weight: bold;color: white;">
        Fostering mindfulness among Indian youth
      </h3>
    </div>

    <div class="sign-in-container">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
