import { CookieService } from 'ngx-cookie-service';
import { PdfService } from './../../services/pdf.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FeatureService } from './../../../services/feature.service';
import { Router } from '@angular/router';
import { StudentService } from './../../../student/services/student.service';
import { Component, OnInit } from '@angular/core';
import Storage from '@aws-amplify/storage';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/admin/services/user.service';
import printJS from 'print-js'
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeEntityPopupComponent } from 'src/app/pricing/components/upgrade-entity-popup/upgrade-entity-popup.component';

declare var require: any
const FileSaver = require('file-saver');
@Component({
  selector: 'app-report-status',
  templateUrl: './report-status.component.html',
  styleUrls: ['./report-status.component.scss']
})
export class ReportStatusComponent implements OnInit {
  avgProgress = 0;
  testCompletionData: any = {}
  firstName: string = '';
  lastName: string = '';
  screen = '';
  fetchingReport = false;
  isLargeDevice: boolean;
  isSmallDevice: boolean;
  isAdult = false;
  basicProgress = 0;
  personalProgress = 0;
  downloadButton: boolean = true;
  familyProgress = 0;
  avgProgressForProfile = 0;
  profilePic: any = ''
  educationProgress = 0;
  checkGender: any;
  checkCountry: any;
  othersProgress = 0;
  activePlan = '';
  langCode = 'en'
  langList = [
    {
      title: "English",
      code: 'en'
    },
    {
      title: "Bengali",
      code: "bn"
    },
    {
      title: "Gujarati",
      code: "gu"
    },
    {
      title: "Hindi",
      code: "hi"
    },
    {
      title: "Kannada",
      code: "kn"
    },
    {
      title: "Marathi",
      code: "mr"
    },
    {
      title: "Tamil",
      code: "ta"
    },
    {
      title: "Telugu",
      code: "te"
    },
    {
      title: "Urdu",
      code: "ur"
    }
  ]
  graduationFlag: boolean = false;
  pdfFileName: string;
  textShow: boolean = true;
  isInterest: boolean = false;
  constructor(private _studentService: StudentService,
    private http: HttpClient,
    private _router: Router,
    private breakpointObserver: BreakpointObserver,
    private _userService: UserService,
    private cookieService: CookieService,
    private _authService: AuthService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private _featureService: FeatureService,
    private _pdfService: PdfService) { }

  ngOnInit(): void {

    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isSmallDevice = true;
          this.isLargeDevice = false;
        }
      });
    this.breakpointObserver
      .observe([Breakpoints.Tablet])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isSmallDevice = true;
          this.isLargeDevice = false;
        }
      });
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isLargeDevice = true;
          this.isSmallDevice = false;
        }
      });
    console.log("-------------------",);
    if (this._router.url == '/student/dashboard') {
      this.textShow = false;
    } else {
      this.textShow = true;
    }
    Storage.configure({
      AWSS3: {
        bucket: 'user-full-report', //REQUIRED -  Amazon S3 bucket name
        region: 'ap-south-1' //OPTIONAL -  Amazon service region
      }
    });
    this._authService.checkBasicData()
    if (this.textShow) {
      this._studentService.highlightLeftMenu('/assessment/fullReport', 'child')
    }
    this.assessmentProgressCalc();
    this.getProfilePersentage();
  }

  updateScreen() {
    this.spinner.show()
    this._featureService.getUserPlan().subscribe((planInfo: any) => {
      planInfo = planInfo[0]
      planInfo.plan = this.titleCase(planInfo.plan)
      this.activePlan = planInfo.plan
      if (this.activePlan != 'Essential' && this.activePlan != 'Premium') {
        this.screen = 'first';
      }
      else {
        if (this.avgProgress == 100) {
          this.screen = 'third'
        }
        else {
          this.screen = 'second'
        }
      }
      this.spinner.hide()
    }, error => { console.log(error); this.spinner.hide() })
  }

  assessmentProgressCalc() {
    this.spinner.show()
    this._studentService.getStudentDetails('basic').subscribe((response: any) => {
      if (response && response[0]) {
        let userData = response[0]
        this.firstName = userData['first_name']
        this.lastName = userData['last_name']
        this.testCompletionData = JSON.parse(userData['assessment_details']);
        var allTrue = Object.keys(this.testCompletionData.Aptitude).every((element: any) => (this.testCompletionData.Aptitude[element] === true));
        this.isInterest = this.testCompletionData.Interest;
        let assessmentDetails = response[0].assessment_details ? JSON.parse(response[0].assessment_details) : {}
        let totalTrues = 0;
        for (let key in assessmentDetails) {
          if (assessmentDetails.hasOwnProperty(key)) {
            if (typeof assessmentDetails[key] === 'object' && assessmentDetails[key] !== null) {
              for (let subKey in assessmentDetails[key]) {
                if (assessmentDetails[key].hasOwnProperty(subKey)) {
                  if (assessmentDetails[key][subKey]) {
                    totalTrues++
                  }
                }
              }
            }
            else {
              if (assessmentDetails[key]) {
                totalTrues++
              }
            }
          }
        }
        this.avgProgress = Math.round(totalTrues * 100 / 10);
        //this.avgProgress = 80
        this.updateScreen()
      }
    })

    this._studentService.getStudentDetails('education').subscribe((response: any) => {
      //console.log(response)
      if (response && response[0]) {
        response[0].class_details = response[0].class_details ? JSON.parse(response[0].class_details) : {}
        let grade = response[0].class_details.grade
        if (grade == 'Class 8' || grade == 'Class 9' || grade == 'Class 10' || grade == null || grade == undefined) {
          this.isAdult = false;
        }
        else if (grade == 'Class 11 / FYJC' || grade == 'Class 12 / SYJC') {
          this.isAdult = true;
        } else {
          this.graduationFlag = true;
        }
      }
    }, error => { })
  }

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  generateReport(view) {
    let reportLinkPref = 'https://user-full-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub');
    let type = 'full'
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    this.fetchingReport = true
    this._pdfService.fetchPDFReport(this.langCode, type).subscribe((pdfResponse: any) => {
      console.log(pdfResponse)
      if (pdfResponse && pdfResponse.success) {
        if (view) {
          setTimeout(() => {
            window.open(reportLinkPref + this.makeRandomString(2), '_blank');
            this.pdfFileName = reportLinkPref + this.makeRandomString(2);
          }, 1000)
        }
        else {
          setTimeout(() => {
            this._pdfService.downloadFile(reportLinkPref + this.makeRandomString(4)).subscribe((blob) => {
              FileSaver.saveAs(blob, `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_report_${this.langCode}.pdf`);
              this.pdfFileName = `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_report_${this.langCode}.pdf`;
              // Set a delay to make the button visible after 1 second
              setTimeout(() => {
                this.fetchingReport = false;
              }, 2000);
            })
          }, 1000)

        }
        this.visitCheck('reportDownload')
      }
    }, error => {
      console.log(error)
      this.fetchingReport = false;
    })
  }

  // added generatereport function for short report 01122022
  generateShortReport(view) {
    let reportLinkPref = 'https://user-short-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1

    this.fetchingReport = true
    this._pdfService.fetchPDFShortReport(this.langCode).subscribe((pdfResponse: any) => {
      console.log(pdfResponse)
      if (pdfResponse && pdfResponse.success) {
        if (view) {
          setTimeout(() => {
            window.open(reportLinkPref + this.makeRandomString(2), '_blank');
            this.pdfFileName = reportLinkPref + this.makeRandomString(2);
          }, 1000)
        }
        else {
          setTimeout(() => {
            this._pdfService.downloadFile(reportLinkPref + this.makeRandomString(4)).subscribe((blob) => {
              FileSaver.saveAs(blob, `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_summary_report_${this.langCode}.pdf`);
              this.pdfFileName = `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_summary_report_${this.langCode}.pdf`;

            })
          }, 1000)

        }
        this.visitCheck('reportDownload')
      }
      this.fetchingReport = false
    }, error => {
      console.log(error)
      this.fetchingReport = false;
    })
  }



  initDownload(view) {
    this.langCode = this.cookieService.get('googtrans').substring(4);
    this.langCode = this.langCode ? this.langCode : 'en'
    let reportLinkPref = 'https://user-full-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    this.http.get(reportLinkPref).subscribe((fetchResponse: any) => {
      console.log(fetchResponse);



    }, error => {
      console.log(error)
      if (error.status == 200) {
        // this.openExistingReport(view)
        // commented above code on 19042023
        this.generateReport(view)
      }
      else {
        this.generateReport(view)
      }

    })

  }
  // added initdownload function for the short report summary 01122022
  initDownloadShortReport(view) {
    // debugger;
    this.langCode = this.cookieService.get('googtrans').substring(4);
    this.langCode = this.langCode ? this.langCode : 'en'
    let reportLinkPref = 'https://user-short-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    this.http.get(reportLinkPref).subscribe((fetchResponse: any) => {
      console.log(fetchResponse);



    }, error => {
      console.log(error)
      if (error.status == 200) {
        // this.openExistingShortReport(view)
        // commented above code on 19042023
        this.generateShortReport(view)
      }
      else {
        this.generateShortReport(view)
      }

    })

  }


  makeRandomString(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getExploreCareer() {
    //this._studentService.getStudentDetails('basic').subscribe
  }

  openExistingReport(view) {
    this.fetchingReport = true;
    let reportLinkPref = 'https://user-full-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    if (view) {
      this.fetchingReport = false;
      window.open(reportLinkPref + this.makeRandomString(2), '_blank')
      this.pdfFileName = reportLinkPref + this.makeRandomString(2), '_blank';
    }
    else {
      this._pdfService.downloadFile(reportLinkPref + this.makeRandomString(4)).subscribe((blob) => {
        FileSaver.saveAs(blob, `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_report_${this.langCode}.pdf`);
        this.pdfFileName = `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_report_${this.langCode}.pdf`;

        this.fetchingReport = false;
      })
    }
    this.visitCheck('reportDownload')
  }

  // added openexistingreport function for short report 01122022
  openExistingShortReport(view) {
    this.fetchingReport = true;
    let reportLinkPref = 'https://user-short-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    if (view) {
      this.fetchingReport = false;
      window.open(reportLinkPref + this.makeRandomString(2), '_blank')
      this.pdfFileName = reportLinkPref + this.makeRandomString(2), '_blank';
    }
    else {
      this._pdfService.downloadFile(reportLinkPref + this.makeRandomString(4)).subscribe((blob) => {
        FileSaver.saveAs(blob, `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_summary_report_${this.langCode}.pdf`);
        this.pdfFileName = `${this.firstName.toLowerCase()}_${this.lastName.toLowerCase()}_summary_report_${this.langCode}.pdf`;

        this.fetchingReport = false;
      })
    }
    this.visitCheck('reportDownload')
  }



  visitCheck(type) {
    if (this._studentService.studentData && this._studentService.studentData.explore_career) {
      let exploreCareer = this._studentService.studentData.explore_career ? JSON.parse(this._studentService.studentData.explore_career) : {}
      if (!exploreCareer[type]) {
        exploreCareer[type] = true;
        this._studentService.updateStudentDetails({ explore_career: JSON.stringify(exploreCareer) }, 'basic').subscribe((response) => {
          this._studentService.studentData.explore_career = JSON.stringify(exploreCareer)

        }, error => console.log(error))
      }
    }
    else {
      this._studentService.getStudentDetails('basic').subscribe((response: any) => {
        if (!response.error) {
          this._studentService.studentData = response[0];
          let exploreCareer = this._studentService.studentData.explore_career ? JSON.parse(this._studentService.studentData.explore_career) : {}
          if (!exploreCareer[type]) {
            exploreCareer[type] = true;
            this._studentService.updateStudentDetails({ explore_career: JSON.stringify(exploreCareer) }, 'basic').subscribe((response) => {
              this._studentService.studentData.explore_career = JSON.stringify(exploreCareer)

            }, error => console.log(error))
          }
        }
      })
    }

  }

  goToPricing() {

    this.openDialogForUpgrade();

    // this._router.navigate(['/pricing/plans']);
    // this._studentService.highlightLeftMenu('','parent')
  }

  openDialogForUpgrade(): void {
    let dialogRef = this.dialog.open(UpgradeEntityPopupComponent, { data: { msg: 'Plan has been successfully upgraded.', alertMsgFlag: true, title: 'Success' } });
  }

  goToAssessment() {
    this._router.navigate(['/assessment']);
    this._studentService.highlightLeftMenu('/assessment', 'child')
  }

  goToProfile() {
    this._router.navigate(['/student/profile']);
    this._studentService.highlightLeftMenu('/student/profile', 'child')
  }

  viewReport() {
    this._router.navigate(['/assessment/viewReport']);
    this._studentService.highlightLeftMenu('/assessment/fullReport', 'child')
  }

  openSampleReport() {
    let pdfURL = 'https://user-data-files.s3.ap-south-1.amazonaws.com/Sample+Detailed+Report+for+8th+-+10th+STD+-+Edited.pdf'
    if (this.isAdult) {
      pdfURL = 'https://user-data-files.s3.ap-south-1.amazonaws.com/Sample+Detailed+Report+for+11th+-+12th+STD+-+Edited.pdf'
    } else if (this.graduationFlag) {
      pdfURL = 'https://user-data-files.s3.ap-south-1.amazonaws.com/Sample+Detailed+Report+for+Graduation+%E2%80%93+2nd+Year.pdf'
    }
    window.open(pdfURL)
    this.visitCheck('sampleReport')
  }



  getProfilePersentage() {
    this._studentService.getStudentDetails('basic').subscribe((data: any) => {
      if (!data.error) {
        this.calculateProgress('basic', data);
        this.firstName = data[0].first_name;
        this.checkGender = data[0].gender;
        this.checkCountry = data[0].state;

        Storage.configure({
          AWSS3: {
            bucket: 'studentuserprofile', //REQUIRED -  Amazon S3 bucket name
            region: 'ap-south-1' //OPTIONAL -  Amazon service region
          }
        });
        this._authService.getCurrentUserObj().then((cognitoUser) => {
          sessionStorage.setItem('userSub', cognitoUser.attributes.sub)
          this._studentService.updateSub()
          Storage.get(cognitoUser.attributes.sub).then((imageData: any) => {
            fetch(imageData).then(response => {
              if (response.status == 200) {
                this.profilePic = imageData;
                this.calculateProgress('basic', data)
              }
            }).catch(error => {
              console.log(error)
            })
            //this.imageUrl = data;
          }).catch(error => { console.log(error) })
        });

      }
    })
    this._studentService.getStudentDetails('personal').subscribe((data: any) => {
      if (!data.error) {
        this.calculateProgress('personal', data);
        this.calculateProgress('others', JSON.parse(data[0].others))
      }
      else {
        this.personalProgress = 0;
        this.othersProgress = 0;
      }

    })
    this._studentService.getStudentDetails('education').subscribe((data: any) => {
      if (!data.error) {
        this.calculateProgress('education', data);
      }
      else {
        this.educationProgress = 0;
      }

    })
    this._studentService.getStudentDetails('family').subscribe((data: any) => {
      if (!data.error) {
        this.calculateProgress('family', data);
      }
      else {
        this.familyProgress = 0;
      }

    })
  }


  calculateProgress(section, data) {
    if (data && !data.error) {
      data = data[0];
    }
    let dontCount = [];
    if (section == 'basic') {
      dontCount = ['nationality', 'isDeleted', 'social_media', 'social_subscriptions', 'total_earn_points', 'plan_details', 'updated_at', 'created_at', 'id', 'uuid']
    }
    if (section == 'personal') {
      dontCount = ['created_at', 'updated_at', 'others', 'id', 'uuid']
    }
    if (section == 'family') {
      // dontCount = ['created_at', 'updated_at', 'id', 'uuid', 'sibling_details']
      dontCount = ['updated_at', 'created_at', 'id', 'uuid', 'sibling_details', 'father_orgnisation', 'mother_orgnisation'];

    }
    if (section == 'education') {
      dontCount = ['created_at', 'updated_at', 'id', 'uuid']
    }
    if (section == 'others') {
      //dontCount = ['created_at','updated_at']
      dontCount = ['treatment_details', 'learningDisability_details', 'learning_disability_name', 'health_list', 'health_Details', 'updated_at', 'created_at'];
    }
    let filled = 0;
    let totalFields = 0;
    for (let key in data) {
      if (dontCount.includes(key)) {
        continue;
      }
      if (data[key] && data[key] != '[]' && data[key].length != 0 && data[key] != '{}') {
        filled++;
      }
      totalFields++;
    }
    if (section == 'basic') {
      /*if(this.emailFetched) {
        filled++;
      }*/
      if (this.profilePic) {
        filled++;
      }
      totalFields++
      this.basicProgress = Math.round((filled / totalFields) * 100)
    }
    if (section == 'personal') {
      this.personalProgress = Math.round((filled / totalFields) * 100)
    }
    if (section == 'family') {
      this.familyProgress = Math.round((filled / totalFields) * 100)
    }
    if (section == 'education') {
      this.educationProgress = Math.round((filled / totalFields) * 100)
    }
    if (section == 'others') {
      totalFields = 5;
      this.othersProgress = Math.round((filled / totalFields) * 100)
    }
    this.getMandatoryProfileCount()
  }

  // updateAverageProgress() {
  //   this._userService.getProfileCount(sessionStorage.getItem('userSub')).subscribe(
  //     (data:any) => {
  //       this.avgProgressForProfile = data.profile_completed;
  //       if(this.avgProgressForProfile == 100){
  //         this.downloadButton = false;
  //       }else{
  //         this.downloadButton = true;
  //       }
  //   },error => {
  //       console.log("error",error);
  //     }
  //   )
  // }

  getMandatoryProfileCount() {
    this._userService.getMandatoryProfileCount(sessionStorage.getItem('userSub')).subscribe(
      (data: any) => {
        this.avgProgressForProfile = data.profile_completed;
        console.log('this.avgProgressForProfile--------------', this.avgProgressForProfile)
        if (this.avgProgressForProfile == 100) {
          this.downloadButton = false;
        } else {
          this.downloadButton = true;
        }
      }, error => {
        console.log("error", error);
      }
    )

  }

  onPrint(view) {

    this.langCode = this.cookieService.get('googtrans').substring(4);
    this.langCode = this.langCode ? this.langCode : 'en'
    let reportLinkPref = 'https://user-full-report.s3.ap-south-1.amazonaws.com/'
    let uuid = sessionStorage.getItem('userSub')
    let randomString1 = this.makeRandomString(6)
    reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1
    this.http.get(reportLinkPref).subscribe((fetchResponse: any) => {
      console.log('fetchResponse-------------', fetchResponse)
    }, error => {

    })
    printJS({ printable: reportLinkPref += uuid + '/' + this.langCode + ".pdf?cache=" + randomString1, type: 'pdf', showModal: true })
  }



}
