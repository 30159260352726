import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IMantraJapLocalData, MantraJapPayload } from 'src/app/yuvachetna/mantra-jap-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class YuvachetnaService {
  isPostLiked(post_id: any) {
    throw new Error('Method not implemented.');
  }
  likeCount(comment_id: any) {
    throw new Error('Method not implemented.');
  }
  private apiUrlStud: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '',
    })
  };

  private playCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public playCount$: Observable<number> = this.playCountSubject.asObservable();
  private isPlayingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isPlaying$: Observable<boolean> = this.isPlayingSubject.asObservable();
  private japCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public japCount$: Observable<number> = this.japCountSubject.asObservable();
  private japTimeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('0:0');
  public japTime$: Observable<string> = this.japTimeSubject.asObservable();
  private playPauseVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public playPauseVisible$: Observable<boolean> = this.playPauseVisibleSubject.asObservable();
  private audio: HTMLAudioElement;
  private isPlaying: boolean = false;
  public playCount: number = 0;
  public japCounts: number = 0;
  public japMins: string = '0:0';
  public songs: songList[] = [];
  isEnglish: boolean = false;
  private isEnglishSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isEnglish$: Observable<boolean> = this.isEnglishSubject.asObservable();
  public uuid = '';
  private translateLangSub: BehaviorSubject<string> = new BehaviorSubject<string>('English');
  public translateLang$: Observable<string> = this.translateLangSub.asObservable();
  mantraData: IMantraJapLocalData;
  private postIdSubject = new Subject();
  public postId$ = this.postIdSubject.asObservable();
  private refreshNeeded = false;

  constructor(private http: HttpClient,
    private router: Router) {
    const BASE_API_URL_Student = environment.ApiConfig.student;
    this.uuid = sessionStorage.getItem('userSub');
    this.apiUrlStud = `${BASE_API_URL_Student}/student`;

    this.mantraData = localStorage.getItem('mantraData')
      ? JSON.parse(localStorage.getItem('mantraData'))
      : {
        jap_count: 0,
        play_count: 0,
        total_time_jap: '0:0'
      };

    const jsonString = JSON.stringify(this.mantraData);
    localStorage.setItem("mantraData", jsonString)

    this.translateLang$.subscribe(res => {
      this.songs = res === 'English' ? this.getEnglishSongs() : this.getHindiSongs();
    })
    // Initialize audio element
    this.audio = new Audio();
    // this.audio.src = "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/gayatri-mantra.wav";

    // check if jap count exists in local storage
    const japStored = localStorage.getItem('japCount');
    if (japStored) {
      this.japCountSubject.next(parseInt(japStored, 10));
      this.japCounts = parseInt(japStored, 10);
    }

    // check if jap Time exists in local storage
    const japTimeStored = localStorage.getItem('japTime');
    if (japTimeStored) {
      this.japTimeSubject.next(japTimeStored);
      this.japMins = japTimeStored;
    }

    // Listen for the ended event 
    this.audio.addEventListener('ended', () => {
      this.playCount++;

      let mantraData: IMantraJapLocalData = localStorage.getItem('mantraData')
        ? JSON.parse(localStorage.getItem('mantraData'))
        : {
          jap_count: 0,
          play_count: 0,
          total_time_jap: '0:0'
        };
      mantraData.play_count += 1;

      localStorage.setItem("mantraData", JSON.stringify(mantraData));
      // this.playCountSubject.next(this.playCount);
      this.audio.play();
    });

    // Listen for route changes to stop the audio
    this.router.events.subscribe((event) => {
      if (this.audio && event instanceof NavigationStart) {
        if (this.isPlaying) {
          this.audio.play();
        }
      }
    });

  }

  updateTranslateLang(newValue: string): void {
    this.translateLangSub.next(newValue);
  }

  getTranslateLang() {
    return this.translateLang$;
  }

  getSongList() {
    return this.songs;
  }

  getJapCount() {
    return this.japCount$;
  }

  getVisibility() {
    return this.playPauseVisible$;
  }

  setVisibility(val: boolean) {
    this.playPauseVisibleSubject.next(val);
  }

  // setJapTime(val: string) {
  //   const previousTime = localStorage.getItem('japTime');
  //   let totalTime: number = 0;

  //   if (previousTime) {
  //     const previousTimeParts = previousTime.split(':');
  //     const previousMinutes = parseInt(previousTimeParts[0], 10);
  //     const previousSeconds = parseInt(previousTimeParts[1], 10);
  //     totalTime = previousMinutes * 60 + previousSeconds;
  //   }

  //   const currentTimeParts = val.split(':');
  //   const currentMinutes = parseInt(currentTimeParts[0], 10);
  //   const currentSeconds = parseInt(currentTimeParts[1], 10);
  //   const newTotalTime = totalTime + currentMinutes * 60 + currentSeconds;

  //   const newMinutes = Math.floor(newTotalTime / 60);
  //   const newSeconds = newTotalTime % 60;

  //   const newTime = `${newMinutes}:${newSeconds}`;
  //   this.japTimeSubject.next(newTime);
  //   localStorage.setItem('japTime', newTime);
  // }

  // getJapTime() {
  //   return this.japTime$;
  // }
  playPause() {

    if (this.isPlaying) {
      this.audio.pause();
    } else {
      this.audio.load();
      this.audio.play();
    }
    this.isPlaying = !this.isPlaying;
    this.isPlayingSubject.next(this.isPlaying)
  }

  getPlayCount(): Observable<number> {
    return this.playCount$;
  }

  selectedSong(song) {
    if (this.audio.src !== song.url) {
      this.audio.src = song.url; // Set the audio source to the selected song URL
      this.audio.load(); // Load the new audio source
    }
    this.playPause();
  }

  isPlay(): Observable<boolean> {
    return this.isPlaying$
  }

  getKnowledgeVideos() {
    return this.http.get(`${this.apiUrlStud}/yuvachetna/Knowledge-library`, this.httpOptions);
  }

  saveMantraJap(mantraData: IMantraJapLocalData) {

    let payloads: MantraJapPayload = {
      jap_count: mantraData.jap_count,
      play_count: mantraData.play_count,
      mins_jap: mantraData.total_time_jap,
      uuid: sessionStorage.getItem('userSub')
    }
    console.log("line no. 209", payloads);
    return this.http.post(`${this.apiUrlStud}/yuvachetna/jap`, payloads)
  }

  getCommnunityData() {
    return this.http.get(`${this.apiUrlStud}/yuvachetna/community`, this.httpOptions);
  }

  getLeaderBoardData() {
    return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/leaderboard?type=${"jap"}`, this.httpOptions);
  }

  getLeaderBoradDataForMantraJap() {
    return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/leaderboard?type=${"mantra"}`, this.httpOptions);
    
  }
  getCommunityCommentData(id) {
    
    return this.http.get(`${this.apiUrlStud}/yuvachetna/community/comments?post_id=${id}`, this.httpOptions);
  }

  getPostId() {
    return this.postId$;
  }
  setPostId(id) {
    this.postIdSubject.next(id);
  }

  postDecription(description: string, uuid, saveProfilePic): Observable<any> {
    const postData = {
      "description": description,
      "user_id": uuid,
      "post_image":saveProfilePic
      // "post_image":post_image
    };
    
    return this.http.post(`${this.apiUrlStud}/yuvachetna/community`, postData);
  }
  
  postData2(post_id, addCommentForPost, uuid,commentAuthorName,commentAuthorProfile): Observable<any> {
    console.log("post_id", post_id,)
    const postData = {
      "comment": addCommentForPost,
      "post_id": post_id,
      "user_id": uuid,
      "author": commentAuthorName,
      "author_image":commentAuthorProfile
    };
    
    return this.http.post(`${this.apiUrlStud}/yuvachetna/community/comments`, postData);
  }

 likePost(post_id: any, action: string): Observable<any> {
  const likeData = {
    action: action,
    post_id: post_id
  };

  return this.http.patch(`${this.apiUrlStud}/yuvachetna/community`, likeData);
}

  // likeComment(comment_id: string): Observable<any> {
  //   // Send a PATCH request to increment likes for the specified comment
  //   const likeData = {
  //     "action": "like",
  //     "comment_id": comment_id
  //   };
  //   return this.http.patch(`${this.apiUrlStud}/yuvachetna/community/comments`, likeData);
  // }
likeComment(comment_id: string, user_id: string, action: string) {
  const likeData = {
    "comment_id": comment_id,
    "user_id": user_id,
     "action": action,
  };
  return this.http.patch(`${this.apiUrlStud}/yuvachetna/community/comments`, likeData);
}

  getMantraJapData() {
    this.uuid = sessionStorage.getItem('userSub');
    return this.http.get(`${this.apiUrlStud}/yuvachetna/jap?uuid=${this.uuid}`)
  }

  getDashboardGraphChart(data, entity_id) {

    // return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?type=days&month=1&entity_id=28`);

    if (data.selected == 'Days') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?dailyFrom=${data.fromdate}&dailyTo=${data.todate}&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?type=days&month=1&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      }
    } else if (data.selected == 'Months') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?monthFrom=${data.fromdate}&monthTo=${data.todate}&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?type=month&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      }
    } else if (data.selected == 'Year') {
      if (data.val == 2) {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?yearFrom=${data.fromdate}&yearTo=${data.todate}&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      } else {
        return this.http.get(`${this.apiUrlStud}/yuvachetna/jap/graph?type=year&entity_id=${entity_id}&uuid=${this.uuid}`, this.httpOptions);
      }
    }

  }

  setLangFlag(val: boolean) {
    this.isEnglishSubject.next(val);
  }

  getLangFlag() {
    return this.isEnglish$;
  }

  // Admin delete Comment and Edit POST

  deleteStudentComment(comment_id) {
  return this.http.delete(`${this.apiUrlStud}/yuvachetna/community/comments/update?comment_id=${comment_id}`);
  }

  deletePostByAdmin(post_id) {
    return this.http.delete(`${this.apiUrlStud}/yuvachetna/community?post_id=${post_id}`);
  }
  
updateComment(commentId: string, updatedComment: string): Observable<any> {
  const payload = {
    comment_id: commentId,
    updated_comment: updatedComment
  };

  return this.http.patch(`${this.apiUrlStud}/yuvachetna/community/comments/update`, payload);
}
  
  updatePostByAdmin(postId: number, postContent: string,postImage) {
     const updatedPostByAdmin = {
    "post_id": postId,
    "description": postContent,
    "post_image": postImage
  };

  return this.http.patch(`${this.apiUrlStud}/yuvachetna/community/update`, updatedPostByAdmin);
  }

  getEnglishSongs(): songList[] {
    return [
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Suresh+Wadkar.wav", name: 'Gayatri Mantra By Suresh Wadkar' },
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Lata+Mangeshkar.mp3", name: 'Gayatri Mantra By Lata Mangeshkar' },
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Anuradha+Paudwal.wav", name: 'Gayatri Mantra By Anuradha Paudwal' }
    ];
  }

  getHindiSongs(): songList[] {
    return [
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Suresh+Wadkar.wav", name: 'सुरेश वाडकर द्वारा गाया हुआ गायत्री मंत्र' },
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Lata+Mangeshkar.mp3", name: 'लता मंगेशकर द्वारा गाया हुआ गायत्री मंत्र' },
      { url: "https://psychometric-assessment.s3.ap-south-1.amazonaws.com/audio/Gayatri+Mantra_Anuradha+Paudwal.wav", name: 'अनुराधा पौडवाल द्वारा गाया हुआ गायत्री मंत्र' }
    ];
  }

  setRefreshNeeded() {
    this.refreshNeeded = true;
  }

  isRefreshNeeded(): boolean {
    return this.refreshNeeded;
  }

  markRefreshDone() {
    this.refreshNeeded = false;
  }

  

}

interface songList {
  url: string,
  name: string
}