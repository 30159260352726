<!-- <img id="lang-tooltip" style="position: absolute;right: 37.5%;height:30px;top:17px;z-index:4" class="information-icon" matTooltipClass="toltipclass2"  matTooltipPosition="below" matTooltip="Please re-select the language if you face problems during translation." src="../../../../assets/svg/info_new.svg"> -->

<!-- <div class="play-pause-container" *ngIf="isVisible">
    <div class="close-container">
        <img height="13px" class="close_popup" src="../../../../assets/svg/feedback/close.svg" (click)="closePlayPause()" />
    </div>
    <img class="card-img-top"
        [src]="isPlaying ? '../assets/yuvachetna/Pause-mantra.png' : '../assets/yuvachetna/Play-mantra.png'" style="width: 40px; height: 40px;" (click)="playPause()">
</div> -->

<div *ngIf="isVisible" #wrapper class="frame-wrapper" [style.top.px]="position.y" [style.left.px]="position.x"
    (mousedown)="startDrag($event)" (touchstart)="startTouchDrag($event)">
    <div #topBar></div>
    <div class="content-wrapper" [style.width.px]="size.w" [style.height.px]="size.h">
        <div class="top-bar-wrapper"><span style="color: #fff; font-weight: bolder;">{{isPlaying ? 'Play' :
                'Pause'}}</span></div>
    </div>
    <div class="resizers" [style.width.px]="size.w" [style.height.px]="size.h"></div>

    <!-- Close Container -->
    <div class="close-container" style="position: absolute; top: -9px; right: -2px;">
        <img height="13px" class="close_popup" src="../../../../assets/svg/feedback/close.svg"
            (click)="closePlayPause()" (touchend)="closePlayPauseTouch()" />
    </div>

    <!-- Play/Pause Image -->
    <img class="card-img-top"
        [src]="isPlaying ? '../assets/yuvachetna/Pause-mantra.png' : '../assets/yuvachetna/Play-mantra.png'"
        style="width: 40px; height: 40px; position: absolute; top: 67%; left: 50%; transform: translate(-50%, -50%);"
        (click)="playPause()" (touchend)="playPauseTouch()" />
</div>


<!-- commenting due to language translator api is not working 28022023 -->
<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" type="ball-pulse" color="#f36d39" [fullScreen]="true">
    <div class="anim-circle">
        <p>
            <img src="../assets/svg/proteen-arrow.svg" width="40px" height="40px">
        </p>
    </div>
</ngx-spinner>

<div class="overlay" *ngIf="_psychometricService.showOverlayInterest">
    <div class="row pr-3 pt-2 d-flex align-item-center justify-content-end" style="height: 60px;">

    </div>
    <div class="row" style="height:calc(100% - 160px)">
        <div class="col-2 noMobile">
        </div>
        <div class="col-12 col-md-10 col-lg-10 pl-4 pr-4">
            <div class=" pr-3 pt-2 pb-2 d-flex align-items-center mobile-fixed justify-content-between">
                <h3 class="tab-heading letter-spacing" translate="no">{{titleVal ? titleVal : ''}}
                </h3>
                <img class="cursor-pointer pt-2" *ngIf="!showSkipCloseBtn"
                    src="../../../../assets/svg/assessments/tour/skipbtn.png" style="height: 50px;"
                    (click)="closeTour()">
                <img class="cursor-pointer pt-2" *ngIf="showSkipCloseBtn"
                    src="../../../../assets/svg/assessments/tour/closebutton.png" style="height: 50px;"
                    (click)="closeTour()">
            </div>
            <div class="col-12 col-md-12 col-sm-12 col-lg-12 pl-0 pr-0 mobile-box assessment-profile-section">
                <div class="pl-4" style="display:flex;">
                    <h4 style="color: white;margin-bottom: 0px;" translate="no">Choose the options that appeal the most
                        to you. You can select multiple options that you like.</h4>
                </div>
                <div class="row" id="content">
                    <ng-container *ngIf="isLargeDevice">
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg ? "../../../../assets/svg/assessments/tour/qa1.svg": "../../../../assets/svg/assessments/tour/qa1-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg3 ? "../../../../assets/svg/assessments/tour/qa2.svg": "../../../../assets/svg/assessments/tour/qa2-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">

                                <img class="displayed mainQ"
                                    [src]='!changeImg1 ? "../../../../assets/svg/assessments/tour/qa3.svg": "../../../../assets/svg/assessments/tour/qa3-s.svg"'
                                    alt="">

                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg4 ? "../../../../assets/svg/assessments/tour/qa4.svg": "../../../../assets/svg/assessments/tour/qa4-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg2 ? "../../../../assets/svg/assessments/tour/qa5.svg": "../../../../assets/svg/assessments/tour/qa5-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg5 ? "../../../../assets/svg/assessments/tour/qa6.svg": "../../../../assets/svg/assessments/tour/qa6-s.svg"'
                                    alt="">
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isSmallDevice">
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg ? "../../../../assets/svg/assessments/tour/qa1.svg": "../../../../assets/svg/assessments/tour/qa1-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg1 ? "../../../../assets/svg/assessments/tour/qa2.svg": "../../../../assets/svg/assessments/tour/qa2-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">

                                <img class="displayed mainQ"
                                    [src]='!changeImg2 ? "../../../../assets/svg/assessments/tour/qa3.svg": "../../../../assets/svg/assessments/tour/qa3-s.svg"'
                                    alt="">

                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg4 ? "../../../../assets/svg/assessments/tour/qa4.svg": "../../../../assets/svg/assessments/tour/qa4-s.svg"'
                                    alt="">
                            </div>
                        </div>

                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg5 ? "../../../../assets/svg/assessments/tour/qa5.svg": "../../../../assets/svg/assessments/tour/qa5-s.svg"'
                                    alt="">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4 col-md-4">
                            <div class="p-3 mobile-center">
                                <img class="displayed mainQ"
                                    [src]='!changeImg6 ? "../../../../assets/svg/assessments/tour/qa6.svg": "../../../../assets/svg/assessments/tour/qa6-s.svg"'
                                    alt="">
                            </div>
                        </div>
                    </ng-container>
                </div>
                <img class="cursor" #cursoricon src="../../../../assets/svg/assessments/cursorp.svg" alt="">
                <div class="pt-3 d-flex align-items-center justify-content-end pr-5">
                    <div>
                        <img class="pr-2" src="../../../../assets/svg/assessments/tour/backbtn.png"
                            style="height: 40px;">
                    </div>
                    <div>
                        <img class="pr-2" src="../../../../assets/svg/assessments/tour/nextbtn.png"
                            *ngIf="!showNextRepeatBtn" style="height: 40px;" (click)="NextClick()">
                        <img class="pr-2" src="../../../../assets/svg/assessments/tour/repeatbtn.png"
                            *ngIf="showNextRepeatBtn" style="height: 40px;" (click)="RepeatClick()">
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row pr-3 d-flex align-item-center justify-content-end" style="height: 55px;">
    </div>

</div>

<ng-container>
    <personality-tour-guide></personality-tour-guide>
</ng-container>